/* ----------------------------- General --------------------------- */
body {
  background-color: #161617;
  margin: 0;
  height: 100%;
}

.layout {
  min-height: 100vh;
  max-width: 1280px;
  background-color: #161617;
  margin: auto;
}

.h-100vh {
  height: 100vh;
}

.content {
  min-height: 100vh;
}

.white {
  color: #ffffff !important;
}

.flex {
  display: flex;
}

/* ----------------------------- Intro --------------------------- */
.intro-content {
  margin-top: 128px;
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.gradient-text {
  --bg-size: 400%;
  background: linear-gradient(
      270deg,
      #001c30,
      #176b87,
      #2e8a99,
      #64ccc5,
      #c1ece4,
      #dafffb,
      #c1ece4,
      #64ccc5,
      #2e8a99,
      #176b87,
      #001c30
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  font-size: 64px;
  max-width: 60%;
  -webkit-background-clip: text;
  animation: 5s ease-in-out 0s infinite normal none running hLEcf;
  font-weight: 600;
}

@media (prefers-reduced-motion: no-preference) {
  .gradient-text {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

.description {
  color: #ffffff;
  max-width: 724px;
  font-size: 20px;
}

.button-container {
  margin-top: 64px;
  margin-bottom: 128px;
  display: flex;
  gap: 16px;
}

.br-6 {
  border-radius: 6px;
}

.centered {
  text-align: center;
}

.card-container {
  display: flex;
  justify-content: space-between;
}

.description {
  max-width: 724px !important;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.85);
  margin-bottom: 32px;
}

/* Responsive styling */
@media (max-width: 1280px) {
  .intro-content {
    margin-left: 52px;
    margin-right: 52px;
    justify-content: center;
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .intro-content {
    margin-left: 52px;
    margin-right: 52px;
    justify-content: center;
    margin-top: 0;
  }

  .gradient-text {
    font-size: 48px;
    max-width: 90%;
  }

  .description {
    font-size: 18px;
    max-width: 90%;
  }

  .button-container .ant-btn {
    height: 42px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .intro-content {
    margin-left: 52px;
    margin-right: 52px;
    justify-content: center;
    margin-top: 0;
  }

  .gradient-text {
    font-size: 38px;
    max-width: 90%;
  }

  .description {
    font-size: 14px;
    max-width: 90%;
  }

  .button-container {
    margin-top: 48px;
    margin-bottom: 48px;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .button-container .ant-btn {
    width: 100%;
    margin-bottom: 16px;
    height: 38px;
    font-size: 14px;
  }

  .button-container .ant-btn:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .intro-content {
    margin-left: 32px;
    margin-right: 32px;
    justify-content: center;
    margin-top: 0;
  }

  .gradient-text {
    font-size: 28px;
    max-width: 100%;
  }

  .description {
    font-size: 14px;
    max-width: 100%;
  }

  .button-container {
    margin-bottom: 28px;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .button-container .ant-btn {
    width: 100%;
    margin-bottom: 8px;
  }

  .button-container .ant-btn:last-child {
    margin-bottom: 0;
  }
}

/* ----------------------------- Window Scroll --------------------------- */
.reveal {
  position: relative;
  opacity: 0;
}
.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------- Radio --------------------------- */
.mb-40 {
  margin-bottom: 40px;
}

.discount {
  font-size: 12px;
  color: orange;
  margin-left: 4px;
}

.radio-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.radio {
  color: #ffffff;
  font-size: 16px;
  padding-right: 40px;
}

/* ----------------------------- Section --------------------------- */
.section {
  margin-bottom: 96px;
}

.title {
  margin-bottom: 0.5em;
  color: rgba(255, 255, 255, 0.85) !important;
  font-size: 22px;
}

.section-description {
  max-width: 724px !important;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.85);
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
}

.gradient-card {
  width: 400px;
  border-width: 0;
  color: #ffffff;
  background-image: linear-gradient(to right, #2e8a99 0%, #176b87 100%);
}

.card-title {
  color: white !important;
  margin-top: 10px;
  font-weight: 800 !important;
}

.secondary-text {
  color: rgba(255, 255, 255, 0.45) !important;
  margin-top: 0 !important;
  font-weight: 800 !important;
}

.divider {
  border-color: rgba(255, 255, 255, 0.45);
}

.total-price {
  color: #ffffff !important;
  margin-top: 10px;
  font-weight: 800 !important;
}

/* Responsive styling */
@media (max-width: 1280px) {
  .section {
    margin-left: 52px;
    margin-right: 52px;
  }

  .section-description {
    margin-bottom: 48px;
  }

  .radio {
    margin-bottom: 6px;
    width: 100%;
  }

  .card-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
}

@media (max-width: 1024px) {
  .section {
    margin-left: 52px;
    margin-right: 52px;
  }

  .radio {
    margin-bottom: 6px;
    width: 100%;
  }

  .card-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .section {
    margin-left: 52px;
    margin-right: 52px;
  }

  .section-description {
    font-size: 16px;
  }

  .radio {
    margin-bottom: 6px;
    font-size: 16px;
    width: 100%;
  }

  .card-container {
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
  }

  .gradient-card {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .section {
    margin-left: 32px;
    margin-right: 32px;
  }

  .section-description {
    font-size: 14px;
  }

  .radio {
    margin-bottom: 6px;
    font-size: 14px;
  }

  .card-container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  .gradient-card {
    width: 100%;
    margin-bottom: 8px;
  }
}

/* ----------------------------- Arrow --------------------------- */
.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 20px;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.arrows.hidden {
  opacity: 0;
}

.arrows path {
  stroke: #2e8a99;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}
